/* eslint-disable jsx-a11y/iframe-has-title */
import Head from 'next/head';

const ScriptCheq = () => (
  <>
    <Head>
      <script
        async
        src="https://euob.yougreencolumn.com/sxp/i/e7aa1c040545e0698ed0ba1bd50e4d30.js"
        data-cookieconsent="ignore"
        data-ch="cheq4ppc"
        className="ct_clicktrue_77869"
      />
    </Head>

    <noscript>
      <iframe
        src="https://obseu.yougreencolumn.com/ns/e7aa1c040545e0698ed0ba1bd50e4d30.html?ch=cheq4ppc"
        width="0"
        height="0"
        style={{ display: 'none' }}
      />
    </noscript>
  </>
);

export default ScriptCheq;
