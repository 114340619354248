const setImageSize = (
  initialWidth: string,
  initialHeight: string,
): {
  width: number | string | undefined;
  height: number | string | undefined;
  isResized: boolean;
} => {
  if (initialWidth !== undefined && initialHeight !== undefined) {
    const originalWidth = Number(initialWidth);
    const originalHeight = Number(initialHeight);

    if (originalWidth >= originalHeight && originalWidth > 1024) {
      return {
        width: 1024,
        height: 0,
        isResized: true,
      };
    }

    if (originalHeight > 1024) {
      return {
        width: 0,
        height: 1024,
        isResized: true,
      };
    }
  } else {
    return { width: undefined, height: undefined, isResized: false };
  }

  return { width: initialWidth, height: initialHeight, isResized: false };
};

export const getOptimizedImageUrl = (imageUrl: string) => {
  if (imageUrl.endsWith('.svg')) {
    return imageUrl;
  }

  const imageSize = imageUrl.split('/')[5]?.split('x');
  const [initialWidth, initialHeight] =
    imageSize?.length === 2 ? imageSize : [];

  const {
    width: finalWidth,
    height: finalHeight,
    isResized,
  } = setImageSize(initialWidth, initialHeight);

  if (isResized) {
    return `${imageUrl}/m/${finalWidth}x${finalHeight}`;
  }

  return `${imageUrl}/m/`;
};

export const customAssetsDomain = () => {
  return 'https://assets.karen.dev.product.frankfurt.gostudent.cloud';
};

export const replaceSbDomainWithCustomAssetsDomain = (src?: string) => {
  if (process.env.useStoryblokAssetsDomain) {
    return src;
  }

  if (src && src.includes('https://a.storyblok.com')) {
    return src.replace('https://a.storyblok.com', customAssetsDomain());
  }

  return src;
};
