import {
  SbBlokData,
  StoryblokComponent,
  storyblokEditable,
} from '@storyblok/react';
import Head from 'next/head';
import { useEffect, useState } from 'react';

import { Cookiebot, Gtm, Optimizely } from '@/components';
import { GtmIdContext } from '@/helpers/contexts';
import HrefData, { HrefDataBlokProps } from '@/blocks/HrefData/HrefData';
import ScriptCheq from '@/components/ScriptCheq/ScriptCheq';
import { landingPageFonts } from '../../helpers/text/fonts';
import Footer, { FooterBlokProps } from '../Footer/Footer';
import {
  buildRedirectPageUrl,
  getDataForParamValue,
} from './templatePageUtils';
import TemplateDataContextProvider from '../TemplateData/TemplateDataContext';

export interface TemplatePageBlokProps extends SbBlokData, MpcData {
  config?: {
    content: {
      footer: Array<FooterBlokProps>;
    };
  };
  content: Array<SbBlokData>;
  cookiebotScripts?: 'disabled' | 'enabled' | '';
  gtmScripts?: 'disabled' | 'enabled' | '';
  hrefLinks: Array<HrefDataBlokProps>;
  optimizelyId?: string;
  seoContent?: Array<SbBlokData>;
  templateDataSet?: Array<string>;
  templateParam?: string;
  templateFallbackPage?: Link;
}

interface TemplatePageProps {
  blok: TemplatePageBlokProps;
}

const TemplatePage = ({ blok, ...restProps }: TemplatePageProps) => {
  const {
    config: { content: { footer: [footer] = [] } = {} } = {},
    content = [],
    cookiebotScripts,
    gtmScripts,
    hrefLinks = [],
    optimizelyId,
    templateDataSet = [],
    templateParam,
    templateFallbackPage,
    seoContent = [],
  } = blok;
  const [templateData, setTemplateData] = useState<Record<string, string>>({});

  useEffect(() => {
    const fetchTemplateData = async () => {
      if (window && templateParam) {
        const urlObject = new URL(window.location.href);
        const { searchParams } = urlObject;
        const templateParamValue = searchParams.get(templateParam);

        if (templateParamValue) {
          const data = await getDataForParamValue(
            templateParamValue,
            templateDataSet,
          );

          if (Object.keys(data).length) {
            setTemplateData(data);
          } else {
            const redirectUrl = await buildRedirectPageUrl(
              templateFallbackPage,
            );

            if (redirectUrl) {
              setTimeout(() => {
                window.location.assign(redirectUrl);
              }, 0);
            }
          }
        }
      }
    };

    fetchTemplateData();
  }, [templateParam, templateDataSet]);

  return (
    <GtmIdContext.Provider value="GTM-N7N7Z9T">
      <TemplateDataContextProvider data={templateData}>
        {seoContent?.map((nestedBlok: SbBlokData) => (
          <StoryblokComponent blok={nestedBlok} key={nestedBlok._uid} />
        ))}
        <Head>{landingPageFonts()}</Head>
        {hrefLinks &&
          hrefLinks.map((nestedBlock) => (
            <HrefData blok={nestedBlock} key={nestedBlock._uid} />
          ))}
        {process.env.staticPagesBuild === 'true' && <ScriptCheq />}
        {optimizelyId && <Optimizely id={optimizelyId} />}
        {gtmScripts !== 'disabled' && <Gtm serverSide />}
        {cookiebotScripts !== 'disabled' && (
          <Cookiebot language={blok._mpc.language} />
        )}
        <main
          {...storyblokEditable(blok)}
          className="overflow-x-clip w-full font-worksans bg-GSbase/white"
        >
          {content.map((nestedBlok: SbBlokData) => (
            <StoryblokComponent
              blok={nestedBlok}
              key={nestedBlok._uid}
              {...restProps}
            />
          ))}
        </main>
        {footer && <Footer blok={footer} />}
      </TemplateDataContextProvider>
    </GtmIdContext.Provider>
  );
};

export default TemplatePage;
