import {
  SbBlokData,
  StoryblokComponent,
  storyblokEditable,
} from '@storyblok/react';
import cx from 'classnames';
import React from 'react';

import TitleBlok, { TitleBlokProps } from '@/blocks/Title/Title';
import PageContainer from '@/components/PageContainer/PageContainer';

export interface ThreeColumnsSectionBlokProps extends SbBlokData {
  centerColumn?: Array<SbBlokData>;
  id?: string;
  leftColumn?: Array<SbBlokData>;
  rightColumn?: Array<SbBlokData>;
  title?: Array<TitleBlokProps>;
  visibility?: 'enabled' | 'disabled';
  visibilityOnMobile?: 'enabled' | 'disabled';
}

export interface ThreeColumnsSectionProps {
  blok: ThreeColumnsSectionBlokProps;
}

const ThreeColumnsSection = ({ blok }: ThreeColumnsSectionProps) => {
  const {
    id,
    leftColumn = [],
    centerColumn = [],
    rightColumn = [],
    title: [title] = [],
    visibility,
    visibilityOnMobile,
  } = blok;

  if (visibility === 'disabled' && visibilityOnMobile !== 'enabled') {
    return null;
  }

  return (
    <section
      {...storyblokEditable(blok)}
      className={cx('w-full bg-white', {
        'hidden sm:block':
          visibility === 'enabled' && visibilityOnMobile === 'disabled',
        hidden: visibility === 'disabled',
      })}
      id={id}
    >
      <PageContainer>
        <div className="w-full grid grid-cols-6 sm:grid-cols-12 gap-x-2 gap-y-4 sm:gap-x-4 sm:gap-y-6 lg:gap-x-8 lg:gap-y-10">
          {title && (
            <div className="col-span-6 sm:col-span-10 sm:col-start-2 lg:col-span-8 lg:col-start-3">
              <TitleBlok blok={title} headingLevel="h2" />
            </div>
          )}
          <div className="flex flex-col col-span-6 sm:col-span-10 sm:col-start-2 lg:col-span-8 lg:col-start-3 gap-y-4 lg:gap-y-8">
            <div className="grid grid-cols-6 sm:grid-cols-12 gap-2 gap-y-4 sm:gap-4 lg:gap-8">
              <div className="col-span-full sm:col-span-4 gap-2 flex flex-col">
                {leftColumn?.map((nestedBlok: SbBlokData) => (
                  <StoryblokComponent blok={nestedBlok} key={nestedBlok._uid} />
                ))}
              </div>
              <div className="col-span-full sm:col-span-4 gap-2 sm:gap-8 flex flex-col">
                {centerColumn?.map((nestedBlok: SbBlokData) => (
                  <StoryblokComponent blok={nestedBlok} key={nestedBlok._uid} />
                ))}
              </div>
              <div className="col-span-full sm:col-span-4 gap-2 sm:gap-8 flex flex-col">
                {rightColumn?.map((nestedBlok: SbBlokData) => (
                  <StoryblokComponent blok={nestedBlok} key={nestedBlok._uid} />
                ))}
              </div>
            </div>
          </div>
        </div>
      </PageContainer>
    </section>
  );
};

export default ThreeColumnsSection;
