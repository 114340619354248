import useOptimizely from '@/helpers/useOptimizely';
import { SbBlokData, StoryblokComponent } from '@storyblok/react';
import { AbTestVariationBlokProps } from '../AbTestVariation/AbTestVariation';

export interface AbTestBlokProps extends SbBlokData {
  original: Array<SbBlokData>;
  variations: Array<AbTestVariationBlokProps>;
}

interface AbTestProps {
  blok: AbTestBlokProps;
}

const AbTest = ({ blok }: AbTestProps) => {
  const { optimizelyReady, variationId } = useOptimizely();

  const getVariationData = () => {
    const { variations } = blok;

    const variationData = variations.find(
      (variationBlok) => variationBlok.id === variationId,
    );

    return variationData || null;
  };

  // if the AbTest was used then we need to wait for optimizelyReady, otherwise we do not show anything
  if (!optimizelyReady) {
    return null;
  }

  const variationData = getVariationData();

  return variationId === undefined || !variationData ? (
    <>
      {blok.original?.map((nestedBlok: SbBlokData) => (
        <StoryblokComponent blok={nestedBlok} key={nestedBlok._uid} />
      ))}
    </>
  ) : (
    variationData && <StoryblokComponent blok={variationData} />
  );
};

export default AbTest;
