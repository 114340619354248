import { useContext } from 'react';
import {
  SbBlokData,
  StoryblokComponent,
  storyblokEditable,
} from '@storyblok/react';
import cx from 'classnames';

import CookieSettingsLink, {
  CookieSettingsLinkBlokProps,
} from '@/blocks/CookieSettingsLink/CookieSettingsLink';
import { CtaLinkBlokProps } from '@/blocks/CtaLink/CtaLink';
import PageContainer from '@/components/PageContainer/PageContainer';
import StoryblokLink, {
  isEmptyLink,
} from '@/components/StoryblokLink/StoryblokLink';
import { SocialMediaLinksBlokProps } from '../SocialMediaLinks/SocialMediaLinks';
import { ThemeContext } from '../../helpers/contexts';
import StoryblokImage from '../../components/StoryblokImage/StoryblokImage';

export interface FooterBlokProps extends SbBlokData {
  content: string;
  contentAlignment?: 'left' | 'center';
  disclaimer: string;
  layout: 'micro' | 'micro-social' | 'full';
  link: Link;
  logo?: Asset;
  socialTitle: string;
  variant: 'variantSparchment200' | 'variantSblue500';
  social: Array<SocialMediaLinksBlokProps>;
  legal: Array<CtaLinkBlokProps | CookieSettingsLinkBlokProps>;
}

interface FooterProps {
  blok: FooterBlokProps;
}

const FooterContent = ({
  content,
  link,
}: Pick<FooterBlokProps, 'content' | 'link'>) => (
  <a
    className="text-xs font-medium sm:text-sm lg:text-base"
    href={link.url || link.cached_url}
  >
    {content}
  </a>
);

const Footer = ({ blok }: FooterProps) => {
  const theme = useContext(ThemeContext);
  const {
    content,
    contentAlignment = 'left',
    disclaimer,
    link,
    logo,
    variant,
    layout,
    socialTitle,
    social: [social] = [],
    legal,
    ...rest
  } = blok;

  const renderLegalSection = () => (
    <div className="w-full col-span-12 lg:col-start-2 lg:col-span-10">
      <ul
        className={cx(
          'flex lg:items-center sm:justify-center flex-row flex-wrap gap-6 sm:gap-8 text-GSdeep/500 border-t pt-4',
          {
            'border-b pb-4': layout !== 'full' || disclaimer,
            'border-GSblue/400': variant === 'variantSblue500',
            'text-GSdeep/900': variant === 'variantSparchment200',
          },
        )}
      >
        {legal?.map((legalLink) => {
          if (legalLink.component === 'cookieSettingsLink') {
            return (
              <CookieSettingsLink
                blok={legalLink}
                className={cx('flex font-regular text-xs', {
                  'text-GSdeep/900': variant === 'variantSparchment200',
                  'text-GSbase/white': variant === 'variantSblue500',
                })}
                key={legalLink._uid}
              >
                {legalLink.label}
              </CookieSettingsLink>
            );
          }
          return isEmptyLink(legalLink.link) ? null : (
            <li key={legalLink._uid}>
              <StoryblokLink
                link={legalLink.link}
                className={cx('flex font-regular text-xs', {
                  'text-GSdeep/900': variant === 'variantSparchment200',
                  'text-GSbase/white': variant === 'variantSblue500',
                })}
                testId={blok._uid}
              >
                {legalLink.label}
              </StoryblokLink>
            </li>
          );
        })}
      </ul>
    </div>
  );

  return (
    <footer
      {...storyblokEditable(blok)}
      className={cx({
        'bg-GSparchment/200 text-GSdeep/900':
          variant === 'variantSparchment200',
        'bg-GSblue/500 text-GSbase/white': variant === 'variantSblue500',
      })}
    >
      <PageContainer
        className={cx('xl:!py-10', {
          'lg:!py-12 xl:!py-12': theme === 'ideal',
        })}
      >
        {theme === 'ideal' && (
          <div className="w-full flex flex-col gap-6 lg:gap-8">
            {disclaimer && (
              <p className="text-center font-worksans text-[10px] leading-4 text-GSbase/white lg:text-xs">
                {disclaimer}
              </p>
            )}
            <div
              className={cx(
                'w-full flex flex-col lg:flex-row items-center gap-4',
                { 'justify-center': contentAlignment === 'center' },
              )}
            >
              {logo && <StoryblokImage blok={logo} className="w-8 max-w-8" />}
              <div className="text-center font-worksans text-[10px] leading-4 text-GSbase/white lg:text-xs">
                {content}
              </div>
            </div>
          </div>
        )}
        {layout === 'micro' && theme !== 'ideal' && (
          <div
            className="
              w-full grid grid-cols-6 gap-x-2 gap-y-2 justify-items-center
              sm:grid-cols-12 sm:gap-x-4
              lg:gap-y-6
            "
          >
            {legal?.length > 0 && (
              <div className="w-full mt-4 col-span-6 sm:col-span-12 lg:col-start-2 lg:col-span-10">
                {renderLegalSection()}
              </div>
            )}
            {disclaimer && (
              <p
                className="
                  col-span-6 text-xs font-medium
                  sm:col-span-12 sm:text-sm
                  lg:text-base lg:col-span-5 lg:col-start-2
                "
              >
                {disclaimer}
              </p>
            )}
            <div
              className={cx('col-span-6 sm:col-span-12', {
                'lg:col-span-5 lg:col-end-12 lg:justify-self-end': disclaimer,
                'lg:col-span-10 lg:col-start-2': !disclaimer,
              })}
            >
              <FooterContent content={content} link={link} />
            </div>
          </div>
        )}
        {layout === 'micro-social' && theme !== 'ideal' && (
          <div
            className="
              w-full grid grid-cols-6 gap-x-2 gap-y-4 justify-items-center
              sm:grid-cols-12 sm:gap-x-4
              lg:gap-y-6
            "
          >
            {legal?.length > 0 && (
              <div className="w-full mt-4 col-span-6 sm:col-span-12 lg:col-start-2 lg:col-span-10">
                {renderLegalSection()}
              </div>
            )}
            {disclaimer && (
              <>
                <p
                  className="
                    col-span-6 text-[10px] leading-4 font-medium
                    sm:col-span-12 sm:text-xs
                  "
                >
                  {disclaimer}
                </p>
                <div className="w-full col-span-6 sm:col-span-12 lg:col-span-10 lg:col-start-2">
                  <hr
                    className={cx({
                      'border-GSparchment/400':
                        variant === 'variantSparchment200',
                      'border-GSblue/400': variant === 'variantSblue500',
                    })}
                  />
                </div>
              </>
            )}
            <div className="col-span-6 sm:justify-self-start lg:col-span-5 lg:col-start-2">
              <FooterContent content={content} link={link} />
            </div>
            <div className="col-span-6 sm:justify-self-end lg:col-span-5 lg:col-end-12">
              {social && <StoryblokComponent blok={social} {...rest} />}
            </div>
          </div>
        )}
        {layout === 'full' && theme !== 'ideal' && (
          <div
            className="
              w-full grid grid-cols-6 gap-x-2 gap-y-4 justify-items-center
              sm:grid-cols-12 sm:gap-x-4
              lg:gap-y-6
            "
          >
            <div className="col-span-6 sm:col-span-12 lg:col-span-10 lg:col-start-2 text-lg">
              {socialTitle}
            </div>
            <div className="col-span-6 sm:col-span-12 lg:col-span-10 lg:col-start-2">
              {social && <StoryblokComponent blok={social} {...rest} />}
            </div>
            {legal?.length > 0 && (
              <div className="w-full col-span-6 mt-4 sm:col-span-12 lg:col-start-2 lg:col-span-10">
                {renderLegalSection()}
              </div>
            )}
            {disclaimer && (
              <div
                className="
                  col-span-6 text-[10px] leading-4 font-medium
                  sm:col-span-12
                  lg:text-xs lg:col-span-10 lg:col-start-2
                "
              >
                {disclaimer}
              </div>
            )}
            <div className="w-full col-span-6 sm:col-span-12 lg:col-span-10 lg:col-start-2">
              <hr
                className={cx({
                  'border-GSparchment/400': variant === 'variantSparchment200',
                  'border-GSblue/400': variant === 'variantSblue500',
                })}
              />
            </div>
            <div className="col-span-6 sm:col-span-12 lg:col-span-10 lg:col-start-2">
              <FooterContent content={content} link={link} />
            </div>
          </div>
        )}
      </PageContainer>
    </footer>
  );
};

export default Footer;
