import React from 'react';
import cx from 'classnames';
import { SbBlokData, storyblokEditable } from '@storyblok/react';
import Head from 'next/head';
import { replaceSbDomainWithCustomAssetsDomain } from '@/helpers/image';

export interface ImageSetBlokProps extends SbBlokData {
  alignment?: 'center' | 'cover' | 'left' | 'right';
  altText?: 'string';
  backgroundRepeat?:
    | 'repeat'
    | 'no-repeat'
    | 'repeat-x'
    | 'repeat-y'
    | 'round'
    | 'space';
  backgroundSize?: 'auto' | 'cover' | 'contain';
  backgroundPosition?:
    | 'bottom'
    | 'center'
    | 'left'
    | 'left bottom'
    | 'left top'
    | 'right'
    | 'right bottom'
    | 'right top'
    | 'top';
  desktopImage?: Asset;
  lazyLoading?: boolean;
  mobileImage?: Asset;
  optimize?: boolean;
  preload?: boolean;
  roundedCorners?: boolean;
  tabletImage?: Asset;
  type?: 'background' | 'image';
}

interface ImageSetProps {
  blok: ImageSetBlokProps;
  className?: string;
  parentRef?: React.RefObject<HTMLElement>;
}

const decorateFileUrl = (
  fileUrl: string | undefined,
  options?: { optimize?: boolean; background?: boolean },
) => {
  if (!fileUrl) {
    return fileUrl;
  }

  let decoratedUrl = fileUrl;

  if (options?.optimize && !fileUrl.endsWith('.svg')) {
    decoratedUrl += '/m/';
  }

  if (options?.background) {
    decoratedUrl = `url("${decoratedUrl}")`;
  }

  return decoratedUrl;
};

const ImageSet = ({ blok, className, parentRef }: ImageSetProps) => {
  const {
    alignment = 'center',
    altText,
    backgroundPosition = 'center',
    backgroundRepeat = 'no-repeat',
    backgroundSize = 'auto',
    desktopImage,
    lazyLoading = false,
    mobileImage,
    optimize = true,
    preload = false,
    roundedCorners = false,
    tabletImage,
    type = 'image',
  } = {
    ...blok,
    alignment: blok.alignment || undefined,
    backgroundPosition: blok.backgroundPosition || undefined,
    backgroundRepeat: blok.backgroundRepeat || undefined,
    backgroundSize: blok.backgroundSize || undefined,
    type: blok.type || undefined,
  };

  const mobileFileUrl = (options?: { background: boolean }) =>
    replaceSbDomainWithCustomAssetsDomain(
      decorateFileUrl(
        mobileImage?.filename ||
          tabletImage?.filename ||
          desktopImage?.filename,
        { ...options, optimize },
      ),
    ) || null;

  const tabletFileUrl = (options?: { background: boolean }) =>
    replaceSbDomainWithCustomAssetsDomain(
      decorateFileUrl(
        tabletImage?.filename ||
          mobileImage?.filename ||
          desktopImage?.filename,
        { ...options, optimize },
      ),
    ) || null;

  const desktopFileUrl = (options?: { background: boolean }) =>
    replaceSbDomainWithCustomAssetsDomain(
      decorateFileUrl(
        desktopImage?.filename ||
          tabletImage?.filename ||
          mobileImage?.filename,
        { ...options, optimize },
      ),
    ) || null;

  React.useEffect(() => {
    if (type === 'background' && parentRef && parentRef.current) {
      parentRef.current.style.setProperty(
        'background-image',
        mobileFileUrl({ background: true }),
      );
    }
  }, []);

  React.useEffect(() => {
    if (parentRef && parentRef.current) {
      if (type === 'background') {
        parentRef.current.style.removeProperty('background-image');

        parentRef.current.style.setProperty(
          '--bg-mobile',
          mobileFileUrl({ background: true }),
        );
        parentRef.current.style.setProperty(
          '--bg-tablet',
          tabletFileUrl({ background: true }),
        );
        parentRef.current.style.setProperty(
          '--bg-desktop',
          desktopFileUrl({ background: true }),
        );

        parentRef.current.style.setProperty(
          'background-position',
          backgroundPosition,
        );
        parentRef.current.style.setProperty('background-size', backgroundSize);
        parentRef.current.style.setProperty(
          'background-repeat',
          backgroundRepeat,
        );

        parentRef.current.classList.add('bg-image-set');
      } else {
        parentRef.current.style.removeProperty('--bg-mobile');
        parentRef.current.style.removeProperty('--bg-tablet');
        parentRef.current.style.removeProperty('--bg-desktop');
        parentRef.current.classList.remove('bg-image-set');
      }
    }
  }, [parentRef, blok]);

  if (type === 'image') {
    return (
      <picture {...storyblokEditable(blok)}>
        {desktopImage?.filename && (
          <source
            media="(min-width: 1024px)"
            srcSet={desktopFileUrl() || undefined}
          />
        )}
        {tabletImage?.filename && (
          <source
            media="(min-width: 640px)"
            srcSet={tabletFileUrl() || undefined}
          />
        )}
        <img
          alt={altText}
          className={cx(
            {
              'mx-auto': alignment === 'center',
              'object-cover h-full w-full': alignment === 'cover',
              'ml-auto': alignment === 'right',
              'mr-auto': alignment === 'left',
              'rounded sm:rounded-lg': roundedCorners === true,
            },
            className,
          )}
          srcSet={mobileFileUrl() || undefined}
          loading={lazyLoading ? 'lazy' : 'eager'}
        />
      </picture>
    );
  }

  if (type === 'background' && preload) {
    const mobileBackground = mobileFileUrl();
    const tabletBackground = tabletFileUrl();
    const desktopBackground = desktopFileUrl();

    return (
      <Head>
        {mobileBackground && (
          <link
            rel="preload"
            href={mobileBackground}
            as="image"
            media="(max-width: 639px)"
          />
        )}
        {tabletBackground && (
          <link
            rel="preload"
            href={tabletBackground}
            as="image"
            media="(min-width: 640px) and (max-width: 1023px)"
          />
        )}
        {desktopBackground && (
          <link
            rel="preload"
            href={desktopBackground}
            as="image"
            media="(min-width: 1024px)"
          />
        )}
      </Head>
    );
  }

  return null;
};

export default ImageSet;
