import { isValidPhoneNumber, parsePhoneNumber } from 'libphonenumber-js/max';
import { isValidPhoneNumber as isValidMobilePhoneNumber } from 'libphonenumber-js/mobile';

export const isPhoneValid = (value: string) => {
  const result = isValidPhoneNumber(value);

  if (result) {
    const { country } = parsePhoneNumber(value);
    if (country === 'AT') {
      return isValidMobilePhoneNumber(value);
    }
  }

  return result;
};

// Removes unwanted 0 from the valid number:
// +440201234578 => +44201234578
export const getPrefixedNationalNumber = (value: string) => {
  const { countryCallingCode, nationalNumber } = parsePhoneNumber(value);

  return `+${countryCallingCode}${nationalNumber}`;
};
