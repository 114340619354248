import { SbBlokData } from '@storyblok/react';

import TrustpilotWidgetComponent from '@/components/TrustpilotWidget/TrustpilotWidget';
import { LayoutTypes } from '@/components/TrustpilotWidget/utils';

interface TrustBoxWidgetBlokProps extends SbBlokData {
  fallbackImage: Asset;
  language: string;
  layout: LayoutTypes;
}

interface TrustBoxWidgetProps {
  blok: TrustBoxWidgetBlokProps;
}

const TrustBoxWidget = ({ blok }: TrustBoxWidgetProps) => {
  const { language, layout, fallbackImage } = blok;

  return (
    <TrustpilotWidgetComponent
      language={language}
      layout={layout}
      fallbackImage={fallbackImage}
    />
  );
};

export default TrustBoxWidget;
