import { replaceSbDomainWithCustomAssetsDomain } from '../../helpers/image';

interface StoryblokImageProps {
  className?: string;
  blok: Asset;
  lazyLoading?: boolean;
  primaryAlt?: string;
  fallbackAlt?: string;
  fallbackSrc?: string;
}

const setImageSize = (
  initialWidth: string,
  initialHeight: string,
): {
  width: number | string | undefined;
  height: number | string | undefined;
  isResized: boolean;
} => {
  if (initialWidth !== undefined && initialHeight !== undefined) {
    const originalWidth = Number(initialWidth);
    const originalHeight = Number(initialHeight);

    if (originalWidth >= originalHeight && originalWidth > 1024) {
      return {
        width: 1024,
        height: 0,
        isResized: true,
      };
    }

    if (originalHeight > 1024) {
      return {
        width: 0,
        height: 1024,
        isResized: true,
      };
    }
  } else {
    return { width: undefined, height: undefined, isResized: false };
  }

  return { width: initialWidth, height: initialHeight, isResized: false };
};

const StoryblokImage = ({
  blok,
  className,
  lazyLoading = true,
  primaryAlt,
  fallbackAlt = '',
  fallbackSrc,
}: StoryblokImageProps) => {
  const { alt: assetAlt, filename } = blok;

  if (!filename) {
    if (fallbackSrc) {
      return (
        <img
          loading={lazyLoading ? 'lazy' : 'eager'}
          className={className}
          src={replaceSbDomainWithCustomAssetsDomain(fallbackSrc)}
          alt={primaryAlt || assetAlt || fallbackAlt}
        />
      );
    }

    return null;
  }

  const imageSize = filename.split('/')[5]?.split('x');
  const [initialWidth, initialHeight] =
    imageSize?.length === 2 ? imageSize : [];

  const {
    width: finalWidth,
    height: finalHeight,
    isResized,
  } = setImageSize(initialWidth, initialHeight);

  let imageSrc;

  if (filename.endsWith('.svg')) {
    imageSrc = filename;
  } else {
    imageSrc = `${filename}/m/`;

    if (isResized) {
      imageSrc = `${filename}/m/${finalWidth}x${finalHeight}`;
    }
  }

  return (
    <img
      loading={lazyLoading ? 'lazy' : 'eager'}
      className={className}
      src={replaceSbDomainWithCustomAssetsDomain(imageSrc)}
      alt={primaryAlt || assetAlt || fallbackAlt}
      data-testid={`StoryblokImage-${blok.id}`}
      // removed for now because of Storyblok issue with incorrect
      // width and height in link if the image was edited inside storyblok
      // width={finalWidth}
      // height={finalHeight}
    />
  );
};

export default StoryblokImage;
